define("@1024pix/pix-ui/translations/index", ["exports", "@formatjs/intl", "@1024pix/pix-ui/translations/en", "@1024pix/pix-ui/translations/fr"], function (_exports, _intl, _en, _fr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.flattenObject = flattenObject;
  _exports.formatMessage = formatMessage;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function formatMessage(locale, message, values) {
    return locales[locale].formatMessage({
      id: message
    }, values);
  }

  var locales = {
    fr: (0, _intl.createIntl)({
      locale: 'fr',
      messages: flattenObject(_fr.default)
    }),
    en: (0, _intl.createIntl)({
      locale: 'en',
      messages: flattenObject(_en.default)
    })
  };

  function flattenObject(object) {
    var entries = Object.entries(object);
    var flatEntries = entries.flatMap(function (_ref) {
      var _ref2 = _slicedToArray(_ref, 2),
          key = _ref2[0],
          value = _ref2[1];

      if (_typeof(value) !== 'object') return [[key, value]];
      var childEntries = Object.entries(flattenObject(value));
      return childEntries.map(function (_ref3) {
        var _ref4 = _slicedToArray(_ref3, 2),
            childKey = _ref4[0],
            childValue = _ref4[1];

        return ["".concat(key, ".").concat(childKey), childValue];
      });
    });
    return Object.fromEntries(flatEntries);
  }
});