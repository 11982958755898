define("ember-lifeline/mixins/dom", ["exports", "ember-lifeline/dom-event-listeners", "ember-lifeline/utils/disposable"], function (_exports, _domEventListeners, _disposable) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _typeof(obj) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (obj) { return typeof obj; } : function (obj) { return obj && "function" == typeof Symbol && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj; }, _typeof(obj); }

  /**
   ContextBoundEventListenersMixin provides a mechanism to attach event listeners
   with runloops and automatic removal when the host object is removed from DOM.
  
   @class ContextBoundEventListenersMixin
   @public
   */
  var _default = Ember.Mixin.create({
    /**
     Attaches an event listener that will automatically be removed when the host
     object is dropped from DOM.
        Example:
        ```js
     import Component from 'ember-component';
     import ContextBoundEventListenersMixin from 'ember-lifeline';
        export default Component.extend(ContextBoundEventListenersMixin, {
       didInsertElement() {
         this.addEventListener('.some-item', 'click', (e) => {
           console.log('.some-item was clicked');
         });
       }
     });
     ```
        This can also be used in other ember types like services and controllers. In
     order to use it there an html element reference must be used instead of a
     css selector. This way we can be sure the element actually exists when the
     listener is attached:
        ```js
     import Service from 'ember-service';
     import ContextBoundEventListenersMixin from 'ember-lifeline';
        export default Service.extend(ContextBoundEventListenersMixin, {
       init() {
         this._super(...arguments);
         const el = document.querySelector('.foo');
         this.addEventListener(el, 'click')
       }
     });
     ```
        @method addEventListener
     @param { String | EventTarget } target the DOM selector or element
     @param { String } _eventName the event name to listen for
     @param { Function } _callback the callback to run for that event
     @public
     */
    addEventListener: function addEventListener(target, eventName, callback, options) {
      (false && !(!this.isComponent || this.tagName !== '' || typeof target !== 'string') && Ember.assert('Must provide an element (not a DOM selector) when using addEventListener in a tagless component.', !this.isComponent || this.tagName !== '' || typeof target !== 'string'));
      (false && !(!this.isComponent || typeof target !== 'string' || this._currentState === this._states.inDOM) && Ember.assert('Called addEventListener with a css selector before the component was rendered', !this.isComponent || typeof target !== 'string' || this._currentState === this._states.inDOM));
      (false && !(this.isComponent || typeof target !== 'string') && Ember.assert('Must provide an element (not a DOM selector) when calling addEventListener outside of component instance.', this.isComponent || typeof target !== 'string'));
      var element; // If no element is provided, we assume we're adding the event listener to the component's element. This
      // addresses use cases where we want to bind events like `scroll` to the component's root element.

      if (this.isComponent && typeof eventName === 'function') {
        options = callback;
        callback = eventName;
        eventName = target;
        element = this.element;
      } else {
        element = findElement(this.element, target);
      }

      (0, _domEventListeners.addEventListener)(this, element, eventName, callback, options);
    },

    /**
     @param { String | EventTarget } target the DOM selector or element
     @param { String } _eventName the event name to listen for
     @param { Function } callback the callback to run for that event
     @public
     */
    removeEventListener: function removeEventListener(target, eventName, callback, options) {
      var element; // If no element is provided, we assume we're adding the event listener to the component's element. This
      // addresses use cases where we want to bind events like `scroll` to the component's root element.

      if (this.isComponent && typeof eventName === 'function') {
        callback = eventName;
        eventName = target;
        element = this.element;
      } else {
        element = findElement(this.element, target);
      }

      (0, _domEventListeners.removeEventListener)(this, element, eventName, callback, options);
    },
    destroy: function destroy() {
      (0, _disposable.runDisposables)(this);

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;

  function findElement(contextElement, selector) {
    var selectorType = _typeof(selector);

    var element;

    if (selectorType === 'string') {
      element = contextElement.querySelector(selector);
    } else if (selector.nodeType || selector instanceof Window) {
      element = selector;
    }

    (false && !(!!element) && Ember.assert("Called addEventListener with selector not found in DOM: ".concat(selector), !!element));
    return element;
  }
});